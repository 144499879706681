import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import Carousel from '@components/carousel';

import AppStore from '@images/appstore.svg';
import GooglePlay from '@images/google-play.svg';
import CallIcon from '@images/icon-call.svg';
import RechargeCubacelIcon from '@images/recharge-cubacel.svg';
// import RechargeNautaIcon from '@images/recharge-nauta.svg';
// import MessageIcon from '@images/icon-message.svg';
import PromoMarkerImage from '@images/promo-marker.svg';
import ArrowRightMiniResource from '@images/arrow-right-mini.svg';
import AnnounceImage from '@images/app-announce.png';

const ArrowRightMiniImage = styled(ArrowRightMiniResource)`
  width: 6px;
  margin-right: 20px;
`;

const IconContainer = styled.div`
  width: 35px;

  @media (min-width: 768px) {
    background-color: #0d995330;
    border: 5px solid #fbfbfb;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 67px;
    height: 67px;

    &:after {
      content: " ";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      border: 1px solid #0d995330;
      border-radius: 100%;
    }
  }
`;

const CardIcon = styled.svg`
  width: 35px;
`;

const StoreButton = styled.svg`
  cursor: pointer;
  @media (max-width: 767px) {
    width: 120px;
  }
`;

const IndexPage = () => {
  const { t } = useTranslation();
  const [showComingSoonModal, setShowComingSoonModal] = useState(false);


  const cards = [
    {
      icon: CallIcon,
      title: t('premium_calls'),
      subtitle: t('premium_calls_description'),
      btnLabel: t('call'),
      // btnLink: '/es/menu?prepaid=1',
      onClick: () => setShowComingSoonModal(true),
      promo: false,
    },
    {
      icon: RechargeCubacelIcon,
      title: t('recharge_cubacel_card'),
      subtitle: t('recharge_cubacel_card_description'),
      btnLabel: t('to_recharge'),
      // btnLink: '/es/menu?prepaid=3',
      onClick: () => setShowComingSoonModal(true),
      promo: true,
    },
    /* {
      icon: RechargeNautaIcon,
      title: t('recharge_nauta_card'),
      subtitle: t('recharge_nauta_card_description'),
      btnLabel: t('to_recharge'),
      // btnLink: '/es/menu?prepaid=4',
      onClick: () => setShowComingSoonModal(true),
      promo: false,
    },
    {
      icon: MessageIcon,
      title: t('send_sms'),
      subtitle: t('send_sms_description'),
      btnLabel: t('to_send_sms'),
      // btnLink: '/es/sms/send',
      onClick: () => setShowComingSoonModal(true),
      promo: false,
    }, */
  ];

  const renderPromoMarker = (
    <div className="promo">
      <PromoMarkerImage />
    </div>
  );

  const renderCards = () => {
    return cards.map((item, index) => (
      <Col key={index} md={6} lg={3} className="mb-2 mb-md-4">
        <Card className="pb-0 pb-md-3 shadow-lg">
          <Card.Body>
            <div className="d-flex flex-row flex-md-column align-items-center py-2 py-md-0">
              <IconContainer>
                <CardIcon as={item.icon} />
              </IconContainer>
              <div className="d-flex flex-column align-items-start align-items-md-center ms-2 ms-md-0 flex-grow-1">
                <Card.Title>{item.title}</Card.Title>
                <Card.Subtitle className="text-muted">
                  {item.subtitle}
                </Card.Subtitle>
              </div>
              <Card.Link
                // href={item.btnLink}
                onClick={item.onClick}
                className="btn btn-primary stretched-link mt-3 d-none d-md-block"
              >
                {item.btnLabel}
              </Card.Link>
              <Card.Link
                // href={item.btnLink}
                onClick={item.onClick}
                className="stretched-link d-md-none"
              >
                <ArrowRightMiniImage />
              </Card.Link>
            </div>
            {item.promo && renderPromoMarker}
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <>
      <Layout
        headerProps={{
          disableLogin: true,
          landingOnly: true,
        }}
      >
        <SEO/>

        <Container>
          <Carousel />
          <hr />
        </Container>

        <Container className="features mb-4">
          <div className="text-center my-2 my-md-4">
            <h1>¿Qué deseas hacer?</h1>
          </div>

          <Row className="justify-content-center">{renderCards()}</Row>
        </Container>

        <Container className="pb-4 pb-md-5">
          <div className="text-center">
            <div className="announce">
              <img src={AnnounceImage} alt="Islacel App" />
            </div>
            <h1 className="mt-5 mb-3 mb-md-4">aMéxico app</h1>
            <p className="lead text-muted fw-normal mx-auto">
            Ya está disponible nuestra aplicación para la comodidad de nuestros
            usuarios <br className="d-none d-md-block" />
            con una interfaz simple, rápida y segura.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <StoreButton
                as={AppStore}
                className="me-3"
                onClick={() => setShowComingSoonModal(true)}
              />
              <StoreButton
                as={GooglePlay}
                onClick={() => setShowComingSoonModal(true)}
              />
            </div>
          </div>

          <hr className="my-5" />

          <div className="text-center">
            <h1 className="mb-3 mb-md-4">Sin cargos adicionales ni sorpresas</h1>
            <p className="lead text-muted fw-normal mx-auto">
            No agregamos <b>ningún cargo adicional</b>, todos los precios son
            finales y si hace varias recargas en la{' '}
              <br className="d-none d-md-block" />
            misma orden le damos más <b>descuentos</b>.
            </p>
          </div>
        </Container>
      </Layout>
    
      <Modal show={showComingSoonModal} centered>
        <Modal.Body className="text-center p-6">
          <h1>¡Muy pronto!</h1>
          <p className="my-4">
            Mantente al tanto para descargar la aplicación desde las tiendas...
          </p>
          <Button onClick={() => setShowComingSoonModal(false)}>Aceptar</Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
